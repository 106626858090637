<template>
  <section class="content">
    <div class="card card-primary">
        <div class="card-body">
            <div class="row">
                <div class="col-9">
                    <div class="row form-horizontal">
                        <div class="col-auto">
                            <label class="control-label my-2 ml-2">
                                <h6>Filter Pencarian</h6>
                            </label>
                        </div>
                        <div class="col-auto mb-2">
                            <div class="input-group">
                                <input type="text" ref="daterange" class="form-control" style="border-right: 0" />
                                <div class="input-group-append">
                                    <div class="input-group-text" style="background-color: #fff">
                                        <span><span class="fa fa-calendar"></span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-3 text-right">
                    <div class="btn-group float-right" ref="toolbar">
                        <button type="button" data-action="read" class="btn btn-default"><i class="fa fa-sync-alt"></i></button>
                        <button type="button" data-action="export-xlsx" class="btn btn-default" :disabled="processing" v-on:click="downloadXlsx"><i class="fa fa-download"></i> <span class="d-none d-md-inline-block">Download</span></button>
                    </div>
                </div>
            </div>
            <div class="table-responsive mt-3">
                <table class="table table-hover" ref="tblppob">
                    <thead>
                    <tr>
                        <th>TRANSACTION ID</th>
                        <th>REF CODE</th>
                        <th>DIBUAT</th>
                        <th>USER CODE</th>
                        <th>PRODUCT</th>
                        <th>CATEGORY</th>
                        <th>NO. PELANGGAN</th>
                        <th>NAMA PELANGGAN</th>
                        <th>NOMINAL</th>
                        <th>ADMIN PROVIDER</th>
                        <th>KOMISI</th>
                        <th>HARGA BELI</th>
                        <th>ADMIN SHIPPER</th>
                        <th>HARGA JUAL</th>
                        <th>PROFIT</th>
                        <th>PAYMENT METHOD</th>
                        <th>BIAYA LAYANAN (Rp)</th>
                        <th>TOTAL BAYAR</th>
                        <th>STATUS</th>
                        <th>PAYMENT STATUS</th>
                        <th>TANGGAL PAYMENT <br>(SUKSES)</th>
                        <th>REKONSILIASI</th>
                    </tr>
                    </thead>
                    <tbody @click="handleClick"></tbody>
                </table>
            </div>
        </div>
    </div>
  </section>
  <!-- /.content -->
</template>
<script>
import { createTable, authFetch, formatCurrency } from "@/libs/hxcore";
import vSelect from "vue-select";
import Swal from "sweetalert2";
import Daterangepicker from "daterangepicker";
import "daterangepicker/daterangepicker.css";
import $ from "jquery";
import moment from 'moment';
import "vue-select/dist/vue-select.css";

export default {
  name: "HistoryPpob",
  data() {
    return {
        errors: [],
        method: "",
        roles: "",
        groups: [],
        productsData: [],
        filter: {},
        formTitle: "Form Produk PPOB",
        form: {
            product_id: 0,
            product_category: "",
            product_name: "",
            product_code: "",
            code: "",
            tags: "",
            product_cost: 0,
            product_price: 0,
            default_admin_fee: 0,
            admin_fee: 0,
            denom: 0,
            active: [],
            is_verified: [],
        },
        dateStart: '',
        dateEnd: '',
        dt1: '',
        dt2: '',
        filter: {},
        processing: false,
    };
  },
  components: {
    vSelect,
  },
  created: function () {
    this.roles = this.$route.meta.roles;
    console.log("load initial data", this.$route);

    this.filter.dt1 = moment().add(-1, 'M').startOf("month").format("YYYY-MM-DD");
    this.filter.dt2 = moment().format("YYYY-MM-DD");

    //get data products
    // authFetch("/ppob/backoffice/ppob_transactions_primary")
    //   .then((res) => {
    //     if (res.status === 201) {
    //     } else if (res.status === 400) {
    //     }
    //     return res.json();
    //   })
    //   .then((js) => {
    //     this.productsData = js.data;
    //   });
  },
  methods: {
    onDate() {
        this.table.api().ajax.reload();
    },
    downloadXlsx: function (e) {
        const self = this;
        self.processing = true;
        var filter = JSON.parse(JSON.stringify(self.filter));
            filter.category = $("select[name=category]").val();
            filter.keyword = $("input[name=q]").val();

        var data = Object.keys(filter)
            .map((key) =>
                encodeURIComponent(key) + "=" + (filter[key] === false ? 0 : encodeURIComponent(filter[key]))).join("&");
        authFetch("/ppob/transactions_download", {
            method: "POST",
            body: data,
        }).then((response) => response.blob())
        .then((blob) => {
        setTimeout(() => {
            self.processing = false;
        }, 1000);
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = "history_ppob.xlsx";
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
        });
    },
    handleClick(e) {
      if (e.target.matches(".link-role")) {
        // this.$router.push({ path: "/permit/" + e.target.dataset.id });
        return false;
      }
    },
    submitForm: function (ev) {
      const e = this.$refs;
      var data = Object.keys(this.form)
        .map(
          (key) =>
            encodeURIComponent(key) + "=" + encodeURIComponent(this.form[key])
        )
        .join("&");
      var urlSubmit = "/ppob/backoffice/ppob_transactions";
      if (this.method == "PUT")
        urlSubmit = "/ppob/backoffice/ppob_transactions/" + this.form.id;

      authFetch(urlSubmit, {
        method: this.method,
        body: data,
      })
        .then((res) => {
          if (res.status === 201) {
          } else if (res.status === 400) {
          }
          return res.json();
        })
        .then((js) => {
          this.errors = [];
          if (!js.success) {
            console.log(js.details);

            for (var key in js.details) {
              if (js.details.hasOwnProperty(key)) {
                this.errors.push(js.details[key]);
              }
            }

            return;
          }
          this.table.api().ajax.reload();
          $(e.formDialog).modal("hide");
        });

      ev.preventDefault();
    },
  },
  mounted() {
    const e = this.$refs;
    var self = this;
    moment.locale('id');

    new Daterangepicker(
        this.$refs.daterange, {
            startDate: moment().add(-1, 'M').startOf("month"),
            endDate: moment(),
            locale: {
                format: 'DD/MM/YYYY'
            },
        },
        function (d1, d2) {
            self.dateStart = d1.format("YYYY-MM-DD");
            self.dateEnd = d2.format("YYYY-MM-DD");
            self.filter.dt1 = self.dateStart;
            self.filter.dt2 = self.dateEnd;
            self.onDate();
        }
    );

    this.table = createTable(e.tblppob, {
        title: "",
        frame: false,
        scrollX: true,
        //   roles: this.$route.params.roles,
        roles: ['read'],
        ajax: "/ppob/transactions",
        processing: true,
        toolbarEl: e.toolbar,
        language: {
            processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'},
        order: [
            [1, 'desc']
        ],
        paramData: function (d) {
            var filter = JSON.parse(JSON.stringify(self.filter));
            for (const key in filter) {
                d[key] = filter[key] === false ? 0 : filter[key];
            }
        },
        columns: [
            { data: "transaction_id",
            render: function (data, type, row, meta) {
                return !row.request_code ? '-' : row.transaction_id
            } },
            { data: "ref_code" },
            { data: "created_on",
            render: function (data, type, row, meta) {
                return !data ? '-' : moment(data).format("DD/MM/YYYY HH:mm:ss");
            } },
            { data: "user_code" },
            { data: "product_name" },
            { data: "product_category" },
            { data: "customer_code" },
            { data: "customer_name" },
            { data: "api_nominal",
            render: function (data, type, row, meta) {
                return "Rp " + formatCurrency(data);
            } },
            { data: "api_admin_fee",
            render: function (data, type, row, meta) {
                return "Rp " + formatCurrency(data);
            } },
            { data: "api_komisi",
            render: function (data, type, row, meta) {
                return "Rp " + formatCurrency(data);
            } },
            { data: "item_cost",
            render: function (data, type, row, meta) {
                return "Rp " + formatCurrency(data);
            } },
            { data: "admin_fee",
            render: function (data, type, row, meta) {
                return "Rp " + formatCurrency(data);
            } },
            { data: "item_price",
            render: function (data, type, row, meta) {
                return "Rp " + formatCurrency(data);
            } },
            { data: "profit",
            render: function (data, type, row, meta) {
                return "Rp " + formatCurrency(data);
            } },
            { data: "payment_method" },
            { data: "payment_service_fee",
            render: function (data, type, row, meta) {
                return "Rp " + formatCurrency(data);
            } },
            { data: "grand_total",
            render: function (data, type, row, meta) {
                return "Rp " + formatCurrency(data);
            } },
            { data: "status_msg",
            sorting: false},
            { data: "payment_status_txt",
            sorting: false},
            { 
            data: "status_changed_on",
            class:'text-right',
            render: function(data, type, row, meta) {
                if (data != null) {
                    return moment(data).format('DD/MM/YYYY HH:mm');
                } else {
                    return "-";
                }
            } },
            { 
            data: "reconciled",
            class:'text-right',
            render: function(data, type, row, meta) {
                if (!row.request_code) {
                    return 'Bospay'
                } else {
                    if (data > 0) {
                        return moment(row.reconciled_on).format('DD/MM/YYYY');
                    } else {
                        return "-";
                    }
                }
            } }
        ],
        //   searching: false,
        filterBy: [0, 1, 3, 4, 5, 6, 7],
        initComplete: function () {
            $('.loading-overlay').removeClass('show');
        },
        rowCallback: function (row, data) {
        },
        buttonClick: (evt) => {
            if (evt.role == "create") {
            self.form = {};
            self.method = "POST";
            self.errors = [];
            self.formTitle = "Tambah Produk Item PPOB";
            $(e.formDialog).modal("show");
            } else if (evt.role == "update" && evt.data) {
            self.form = evt.data;
            self.form.active = evt.data.active == 0 ? false : true; //operator d
            self.form.is_verified = evt.data.is_verified == 0 ? false : true; //operator d
            self.method = "PUT";
            self.errors = [];
            self.formTitle = "Edit Produk Item PPOB";
            $(e.formDialog).modal("show");
            } else if (evt.role == "delete" && evt.data) {
            console.log(evt);
            Swal.fire({
                title: "Hapus data?",
                icon: "question",
                denyButtonText: '<i class="fa fa-times"></i> Hapus',
                showCancelButton: true,
                showDenyButton: true,
                showConfirmButton: false,
            }).then((result) => {
                if (result.isDenied) {
                authFetch("/ppob/backoffice/ppob_transactions/" + evt.data.id, {
                    method: "DELETE",
                    body: "id=" + evt.data.id,
                })
                    .then((res) => {
                    return res.json();
                    })
                    .then((js) => {
                    this.table.api().ajax.reload();
                    });
                }
            });
            }
        },
        });
    },
};
</script>